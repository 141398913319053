<template>
    <div>
        <TeamList/>
    </div>
</template>

<script>
import TeamList from '@/components/TeamList'

export default {
  name: 'TeamsEdition',
  components: { TeamList }
}
</script>
