<template>
    <div>
        <CustomContentList/>
    </div>
</template>

<script>
import CustomContentList from '@/components/CustomContentList'

export default {
  name: 'JourneyCustomize',
  components: { CustomContentList }
}
</script>
