<template>
  <div>
    <div class="modal" id="add-custom-content-modal" :class="{'is-active': displayed}">
      <div class="modal-background"></div>
      <div class="modal-card" v-if="formSubmissionInProgress">
        <section class="modal-card-body">
          Envoi en cours...<br/><br/>
          <progress class="progress is-small is-default" max="100">15%</progress>
        </section>
      </div>
      <div class="modal-card" v-if="customContent && !formSubmissionInProgress">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <span v-if="customContentUid === null">Ajouter un contenu</span>
            <span v-if="customContentUid !== null && isFuture">Modifier un contenu</span>
            <span v-if="customContentUid !== null && !isFuture">Contenu</span>
          </p>
          <button class="delete" aria-label="close" @click="cancel()"></button>
        </header>
        <section class="modal-card-body">
          <form ref="customContentForm">
            <div class="field" v-if="!customContentUid">
              <label class="label">Type de contenu</label>
              <div class="control">
                <div class="select">
                    <select v-model="customContent.contentType">
                        <option disabled value="">Choisir un type</option>
                        <option value="TEXT">Texte</option>
                        <option value="IMAGE">Image</option>
                        <option value="VIDEO">Vidéo</option>
                    </select>
                </div>
              </div>
            </div>
            <div class="field" v-if="customContent.contentType=='TEXT'">
              <label class="label">Votre texte</label>
              <div class="control">
                <textarea
                    class="textarea"
                    maxlength="200"
                    required
                    placeholder="Bonne chance à tous !"
                    v-model="customContent.text"
                >
                </textarea>
                <div class="textarea-length" :class="{'warning-length': customContent.text.length > 170, 'danger-length': customContent.text.length === 200}">
                  <span class="text-length">{{ customContent.text.length }}</span>
                  /
                  <span class="max-length">200</span>
                </div>
              </div>
            </div>
            <div class="field" v-if="customContent.contentType=='IMAGE'">
              <label class="label" v-if="customContent.imageUrl">Existant</label>
              <img v-if="customContent.imageUrl" :src="customContent.imageUrl"/>
              <div v-if="isFuture">
                <label class="label">Uploader un fichier</label>
                  <div class="notification is-danger" v-if="imageErrorMessage">
                    {{ imageErrorMessage }}
                  </div>
                <div class="file has-name">
                  <label class="file-label">
                    <input class="file-input" type="file" name="image" required @change="onImageChange" ref="imageInput" accept=".png,.jpeg,.jpg" />
                    <span class="file-cta">
                    <span class="file-icon">
                        <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">
                        Choisir un fichier...
                    </span>
                    </span>
                    <span class="file-name">
                      <span v-if="uploadedImageName">
                        {{ uploadedImageName }}
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="field" v-if="customContent.contentType=='VIDEO'">
              <label class="label">Lien Youtube</label>
              <div class="control">
                <input
                  class="input"
                  ref="youtubeInput"
                  type="text"
                  required
                  placeholder="https://www.youtube.com/watch?v=xxxxxxxxxx"
                  v-model="customContent.youtubeVideo"
                >
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot" v-if="isFuture">
          <button class="button is-success" @click="validate()">Valider</button>
          <button class="button" @click="cancel()">Annuler</button>
          <div class="delete-btn" v-if="customContentUid">
            <button class="button is-danger" @click="deleteContent()">Supprimer</button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin'

export default {
  name: 'CustomContentModal',
  mixins: [BaseMixin],
  props: {
    displayed: {
      required: true
    },
    customContentUid: {
      default: null
    }
  },
  data () {
    return {
      customContent: null,
      uploadedImageName: null,
      imageErrorMessage: '',
      formSubmissionInProgress: false
    }
  },
  created () {
    this.customContent = this.fetchContent(this.customContentUid)
  },
  watch: {
    customContentUid (value) {
      this.customContent = this.fetchContent(this.customContentUid)
    },
    displayed (value) {
      this.customContent = this.fetchContent(this.customContentUid)
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:displayed', false)
    },
    cancel () {
      this.$store.dispatch('refreshCustomContents')
      this.closeModal()
    },
    async deleteContent () {
      await this.$store.dispatch('deleteCustomContent', {
        uid: this.customContentUid
      })
      this.closeModal()
    },
    onImageChange () {
      const input = this.$refs.imageInput
      this.imageErrorMessage = ''
      this.uploadedImageName = null
      if (input.files.length > 0) {
        this.uploadedImageName = input.files[0].name
        if (input.files[0].size > 2097152) {
          this.imageErrorMessage = 'Le fichier doit être inférieur à 2Mo'
          return
        }
        const reader = new FileReader()
        const component = this
        reader.onload = function (e) {
          component.customContent.imageData = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    // Fetch existing content from store
    fetchContent (customContentUid) {
      if (customContentUid === null) {
        // New content
        return {
          contentType: 'TEXT',
          text: 'Votre texte',
          youtubeVideo: '',
          imageUrl: '',
          imageData: ''
        }
      }
      for (const customContent of this.$store.state.customContents) {
        if (customContent.uid === customContentUid) {
          return customContent
        }
      }
      return null
    },
    // Non-HTML 5 validations
    customValidate () {
      if (this.customContent.contentType === 'VIDEO') {
        // Validate Youtube video URL
        const youtubeFormats = [
          /https:\/\/www\.youtube\.com\/watch\?v=(\S+)/,
          /https:\/\/youtu\.be\/(\S+)/,
          /https:\/\/www\.youtube\.com\/embed\/(\S+)/
        ]

        let formatOk = false

        for (const youtubeFormat of youtubeFormats) {
          const matchResult = this.customContent.youtubeVideo.match(youtubeFormat)
          if (matchResult) {
            formatOk = true
            this.customContent.youtubeVideo = 'https://www.youtube.com/embed/' + matchResult[1]
            break
          }
        }

        if (formatOk) {
          this.$refs.youtubeInput.setCustomValidity('')
        } else {
          this.$refs.youtubeInput.setCustomValidity('Format de vidéo invalide. Doit être https://www.youtube.com/watch?v=xxxxx, ou https://youtu.be/xxxxxx, ou https://www.youtube.com/embed/xxxxx')
        }
      }
    },
    async validate () {
      this.customValidate()
      if (this.$refs.customContentForm.reportValidity()) {
        this.formSubmissionInProgress = true
        if (this.customContentUid) {
          await this.$store.dispatch('updateCustomContent',
            {
              uid: this.customContentUid,
              data: this.customContent
            }
          )
        } else {
          await this.$store.dispatch('addCustomContent', this.customContent)
        }
        this.closeModal()
        this.formSubmissionInProgress = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.fa-trash
  &:hover
    cursor: pointer
.delete-btn
  flex-grow: 3
  button
    display: block
    float: right
img
  max-height: 300px

.textarea-length
  margin-top: 15px
  font-size: 12px
  &.warning-length
    font-weight: bold
    color: orange
  &.danger-length
    font-weight: bold
    color: red

.progress
  width: 200px
  margin: auto
  margin-top: 30px
  margin-bottom: 30px
</style>
