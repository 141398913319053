<template>
  <div id="qrcodes-wrapper">
    <div class="notification is-default">
      Voici l'ensemble des QRcodes des équipes.<br/><br/>

      Si un participant ne dispose pas de son invitation par email, il lui suffira alors
      de scanner le QRcode de son équipe.
    </div>

    <button class="button is-link" @click='print()'>
      Imprimer
    </button>

    <div v-if="data.length" class="columns is-multiline">

      <div
        class="team column is-one-third"
        v-for="team in data"
        :key="team.teamName"
      >
        <div class="team-name">
          {{ team.teamName }}
        </div>
        <div class="team-qrcode" v-html="team.qrcode">
        </div>
        <div class="team-members">
          <ul>
            <li
              v-for="playerName in team.playerNames"
              :key="playerName"
            >
              {{ playerName }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin'
import axiosInstance from '@/api'

export default {
  name: 'QrCodes',
  mixins: [BaseMixin],
  data () {
    return {
      data: []
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    async refresh () {
      const response = await axiosInstance.get(this.$store.state.gatheringUid + '/qrcodes')
      this.data = response.data
    },
    print () {
      window.print()
    }
  }
}
</script>

<style lang="sass" scoped>
  #qrcodes-wrapper
    text-align: left

  button
    display: block
    margin: auto
    margin-top: 50px

  .team-name
    text-align: left
    font-size: 18px
    font-weight: bold
    margin: 50px 0px 20px 0px

  .team-members
    text-align: left
    ul
      list-style-type: square
      li
        margin-left: 20px
        padding-top: 10px

  .team-qrcode
    text-align: left
    margin-left: -15px

  @media print
    button
      display: none
    .notification
      display: none

</style>
