<template>
  <div>
    <article class="panel is-link">
      <p class="panel-heading">
        {{ teams.length }} équipe<span v-if="teams.length > 1">s</span> <span id="max-nb-teams">(Nombre max: {{ mainInfos.nbMaxTeams }})</span>
      </p>
      <a class="panel-block is-active"
        v-for="team in teams"
        :key="team.id"
        @click="showEditTeamModal(team.id)"
      >
        <span class="panel-icon">
        <i class="fa-solid fa-people-group" aria-hidden="true"></i>
        </span>
        {{ team.name }} - {{ team.players.length }} joueur<span v-if="team.players.length > 1">s</span>
      </a>
      <div class="panel-block" v-if="!nbMaxTeamsReached && isFuture">
        <button class="button is-link is-outlined is-fullwidth" @click="showAddTeamModal()">
        Ajouter
        </button>
      </div>
    </article>

    <div class="notification is-warning" v-if="nbMaxTeamsReached">
      Vous avez atteint le nombre maximum d'équipes autorisées.<br/><br/>
      Pour ajouter de nouvelles équipes, envoyez un message à votre contact commercial.
    </div>

    <TeamModal v-model:displayed="teamModalDisplayed" :teamId="editedTeamId"/>
  </div>
</template>

<script>
import TeamModal from '@/components/TeamModal'
import BaseMixin from '@/mixins/BaseMixin'

export default {
  name: 'TeamList',
  mixins: [BaseMixin],
  components: { TeamModal },
  data () {
    return {
      teamModalDisplayed: false,
      editedTeamId: null
    }
  },
  computed: {
    nbMaxTeamsReached () {
      return this.teams.length >= this.mainInfos.nbMaxTeams
    }
  },
  methods: {
    showAddTeamModal () {
      this.editedTeamId = null
      this.teamModalDisplayed = true
    },
    showEditTeamModal (teamId) {
      this.editedTeamId = teamId
      this.teamModalDisplayed = true
    }
  }
}
</script>

<style lang="sass" scoped>
  .notification
    text-align: left
    ul
      list-style-type: square
      li
        margin-left: 20px
        padding-top: 10px
  #max-nb-teams
    font-size: 11px
</style>
