<template>
  <div>
    <nav
      v-if="gatheringUid"
      class="navbar is-fixed-top is-white"
      role="navigation"
      id="main-menu"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <router-link :to="{'name': 'Home'}" class="navbar-item sg-title">
            Studio Guimauve
        </router-link>
        <a role="button" class="navbar-burger"
           aria-label="menu" aria-expanded="false"
           @click="toggleBurger()" :class="{'is-active': burgerActive}">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" :class="{'is-active': burgerActive}">
        <div class="navbar-start">
          <router-link :to="{'name': 'Home'}" class="navbar-item">
            Accueil
          </router-link>

          <router-link :to="{'name': 'Teams'}" class="navbar-item">
            Les équipes
          </router-link>

          <router-link :to="{'name': 'Customize'}" class="navbar-item">
            Personnalisation
          </router-link>

          <router-link :to="{'name': 'Invites'}" class="navbar-item" v-if="!isPast">
            Invitations
          </router-link>

          <router-link :to="{'name': 'QrCodes'}" class="navbar-item" v-if="!isPast">
            QR Codes
          </router-link>

          <router-link :to="{'name': 'Photos'}" class="navbar-item" v-if="!isFuture">
            Photos
          </router-link>

          <router-link :to="{'name': 'Rankings'}" class="navbar-item" v-if="!isFuture">
            Classement
          </router-link>
        </div>
      </div>
    </nav>
    <div class="wrapper" v-if="mainInfos.name">
      <h1 class="title">{{ mainInfos.name }}</h1>
      <h2 class="subtitle">
        Le {{ mainInfos.eventDate }}
        <span class="event-date-status" v-if="mainInfos.dateStatus !== 'future'">
          <span v-if="isToday">(aujourd'hui)</span>
          <span v-if="isPast">(passé)</span>
        </span>
      </h2>

      <div class="container">
        <router-view/>
      </div>
    </div>
    <div class="wrapper" v-else>
      <router-view/>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin'

export default {
  name: 'App',
  mixins: [BaseMixin],
  created () {
    this.switchToGathering(this.$route.params.gatheringUid)
  },
  data () {
    return {
      burgerActive: false
    }
  },
  watch: {
    gatheringUid (value) {
      this.switchToGathering(value)
    }
  },
  computed: {
    gatheringUid () {
      return this.$route.params.gatheringUid
    }
  },
  methods: {
    toggleBurger () {
      this.burgerActive = !this.burgerActive
    },
    switchToGathering (value) {
      this.$store.dispatch('switchToGathering', value)
    }
  }

}
</script>

<style lang="sass" scoped>

  .navbar-brand
    background-color: #555
    .navbar-item, .navbar-link
      color: white !important
      &:hover,&:focus,&:visited
        color: white !important
        background-color: inherit !important
        border-radius: inherit !important
    .navbar-burger
      color: white !important

  @media screen and (min-width: 1024px)
    .sg-title
      font-weight: bold
    .navbar-brand
      background-color: #555
      padding-right: 40px
      margin: 10px 20px 10px 0px
      border-radius: 20% 30% 70% 0%

  .event-date-status
    font-size: 12px
    margin-left: 4px

  @media print
    #main-menu
      display: none

</style>

<style lang="sass">
#app
  text-align: center
  color: #2c3e50
  height: 100%

#app, button
  font-family: "Open Sans", Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

div.button,button.button, a.button
  &.is-info
    background-color: #92c1be !important

button.button
  white-space: normal
  height: auto

button.selected
  position: relative
  &.button[disabled]
    opacity: 1
  &:before
    content: ''
    position: absolute
    top: 0px
    left: 0px
    right: 0px
    bottom: 0px
    background-color: #ccc
    opacity: 0.3
    z-index: 1006
  background-color: #92c1be !important
  color: white
  border: solid 1px #608e99

.select select:focus, .textarea:focus, .input:focus, .select select.is-focused, .is-focused.textarea, .is-focused.input, .select select:active, .textarea:active, .input:active, .select select.is-active, .is-active.textarea, .is-active.input
  border-color: #CCC !important

body
  margin: 0

// Trying to disable a bulma CSS affecting columns background
.columns:not(:last-child)
  margin-bottom: 0px !important

h1
  margin-top: 30px !important

</style>
