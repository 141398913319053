import axiosInstance from '@/api'
import { createStore } from 'vuex'

function defaultState () {
  return {
    mainInfos: {},
    teams: [],
    customContents: []
  }
}

export default createStore({
  state: defaultState(),
  actions: {
    switchToGathering (context, gatheringUid) {
      if (!gatheringUid) {
        gatheringUid = null
      }
      context.commit('SET_GATHERING_UID', gatheringUid)
      context.dispatch('refreshMainInfos')
      context.dispatch('refreshTeams')
      context.dispatch('refreshCustomContents')
    },
    async refreshMainInfos (context) {
      if (context.state.gatheringUid === null) {
        context.commit('SET_MAIN_INFOS', {})
        return
      }
      const response = await axiosInstance.get(context.state.gatheringUid + '/main-infos')
      context.commit('SET_MAIN_INFOS', response.data)
    },
    async refreshTeams (context) {
      if (context.state.gatheringUid === null) {
        context.commit('SET_TEAMS', {})
        return
      }
      const response = await axiosInstance.get(context.state.gatheringUid + '/team')
      context.commit('SET_TEAMS', response.data)
    },
    async refreshCustomContents (context) {
      if (context.state.gatheringUid === null) {
        context.commit('SET_TEAMS', [])
        return
      }
      const response = await axiosInstance.get(context.state.gatheringUid + '/custom-content')
      context.commit('SET_CUSTOM_CONTENTS', response.data)
    },
    async createTeam (context, data) {
      await axiosInstance.post(context.state.gatheringUid + '/team', data)
      context.dispatch('refreshTeams')
    },
    async updateTeam (context, team) {
      await axiosInstance.put(context.state.gatheringUid + '/team/' + team.uid, team.data)
      context.dispatch('refreshTeams')
    },
    async deleteTeam (context, team) {
      await axiosInstance.delete(context.state.gatheringUid + '/team/' + team.uid)
      context.dispatch('refreshTeams')
    },
    async addCustomContent (context, data) {
      await axiosInstance.post(context.state.gatheringUid + '/custom-content', data)
      context.dispatch('refreshCustomContents')
    },
    async updateCustomContent (context, customContent) {
      await axiosInstance.put(context.state.gatheringUid + '/custom-content/' + customContent.uid, customContent.data)
      context.dispatch('refreshCustomContents')
    },
    async deleteCustomContent (context, customContent) {
      await axiosInstance.delete(context.state.gatheringUid + '/custom-content/' + customContent.uid)
      context.dispatch('refreshCustomContents')
    },
    async moveUpCustomContent (context, customContentUid) {
      // TODO : Move up first in store

      // Move up in backend
      await axiosInstance.post(context.state.gatheringUid + '/custom-content/' + customContentUid + '/move-up')
      context.dispatch('refreshCustomContents')
    },
    async moveDownCustomContent (context, customContentUid) {
      // TODO : Move down first in store

      // Move down in backend
      await axiosInstance.post(context.state.gatheringUid + '/custom-content/' + customContentUid + '/move-down')
      context.dispatch('refreshCustomContents')
    },
    async sendInvite (context, email) {
      await axiosInstance.post(context.state.gatheringUid + '/invite', {
        email
      })
      await new Promise(resolve => setTimeout(resolve, 2000))
      context.dispatch('refreshTeams')
    },
    async sendAllInvites (context, options) {
      await axiosInstance.post(context.state.gatheringUid + '/invite', {
        force: options.force
      })
      await new Promise(resolve => setTimeout(resolve, 2000))
      context.dispatch('refreshTeams')
    }
  },
  mutations: {
    SET_MAIN_INFOS (state, value) {
      state.mainInfos = value
    },
    SET_TEAMS (state, value) {
      state.teams = value
    },
    SET_CUSTOM_CONTENTS (state, value) {
      state.customContents = value
    },
    SET_GATHERING_UID (state, value) {
      state.gatheringUid = value
    }
  }
})
