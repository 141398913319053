<template>
  <div>
    <div class="modal" id="add-team-modal" :class="{'is-active': displayed}">
      <div class="modal-background"></div>
      <div class="modal-card" v-if="team">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <span v-if="teamId === null">Ajouter une équipe</span>
            <span v-else>
              <span v-if="isFuture">Modifier : </span>
              {{ team.name }}
            </span>
          </p>
          <button class="delete" aria-label="close" @click="cancel()"></button>
        </header>
        <section class="modal-card-body">
          <form ref="teamForm">
            <div class="field">
              <label class="label">Nom de l'équipe</label>
              <div class="control">
                  <input class="input" type="text" required v-model="team.name">
              </div>
            </div>

            <div class="field">
              <label class="label">Membres de l'équipe</label>
            </div>
            <div
              class="field is-horizontal"
              v-for="(player, index) in team.players" :key="player.rank"
            >
              <div class="field-body">
                <div class="field">
                  <p class="control is-expanded has-icons-left">
                    <input class="input" type="text" required placeholder="Nom" v-model="player.name">
                    <span class="icon is-small is-left">
                      <i class="fas fa-user"></i>
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control is-expanded has-icons-left has-icons-right">
                    <input class="input is-success" type="email"
                      required placeholder="Adresse email" v-model="player.email">
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                    <span class="icon is-small is-right">
                      <i class="fas fa-check" v-if="isEmailCorrect(player.email)"></i>
                    </span>
                  </p>
                </div>
                <div class="field" v-if="isFuture">
                  <span class="icon is-small is-right has-text-grey mt-3"
                    @click="deletePlayer(index)">
                    <i class="fas fa-trash"></i>
                  </span>
                </div>
              </div>
            </div>
            <button
              class="button is-default"
              @click="addPlayer()"
              v-if="isFuture"
            >
              Ajouter
            </button>
          </form>
        </section>
        <footer class="modal-card-foot" v-if="isFuture">
          <button class="button is-success" @click="validate()">Valider</button>
          <button class="button" @click="cancel()">Annuler</button>
          <div class="delete-btn" v-if="teamId">
            <button class="button is-danger" @click="deleteTeam()">Supprimer</button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import * as EmailValidator from 'email-validator'
import BaseMixin from '@/mixins/BaseMixin'

export default {
  name: 'TeamModal',
  mixins: [BaseMixin],
  props: {
    displayed: {
      required: true
    },
    teamId: {
      default: null
    }
  },
  data () {
    return {
      team: null
    }
  },
  created () {
    this.team = this.fetchTeam(this.teamId)
  },
  watch: {
    teamId (value) {
      this.team = this.fetchTeam(this.teamId)
    },
    displayed (value) {
      this.team = this.fetchTeam(this.teamId)
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:displayed', false)
    },
    cancel () {
      this.$store.dispatch('refreshTeams')
      this.closeModal()
    },
    addPlayer () {
      this.team.players.push({
        rank: this.team.players.length,
        name: '',
        email: ''
      })
    },
    async deleteTeam () {
      await this.$store.dispatch('deleteTeam', {
        uid: this.teamId,
        data: this.team
      })
      this.closeModal()
    },
    deletePlayer (index) {
      this.team.players.splice(index, 1)
      let rank = 0
      for (const player of this.team.players) {
        player.rank = rank
        rank += 1
      }
    },
    isEmailCorrect (value) {
      return EmailValidator.validate(value)
    },
    // Fetch existing team from store
    fetchTeam (teamId) {
      if (teamId === null) {
        // New team
        return {
          name: '',
          players: []
        }
      }
      for (const team of this.$store.state.teams) {
        if (team.id === teamId) {
          return team
        }
      }
      return null
    },
    async validate () {
      if (this.$refs.teamForm.reportValidity()) {
        if (this.teamId) {
          await this.$store.dispatch('updateTeam',
            {
              uid: this.teamId,
              data: this.team
            }
          )
        } else {
          await this.$store.dispatch('createTeam', this.team)
        }
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.fa-trash
  &:hover
    cursor: pointer
.delete-btn
  flex-grow: 3
  button
    display: block
    float: right
</style>
