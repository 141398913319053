import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'

require('bulma/css/bulma.css')

let app = createApp(App).use(store).use(router)

if (process.env.VUE_APP_SENTRY_URL) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_URL,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({ router })
    ],
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0
  })
}

app = app.mount('#app')

store.$app = app
