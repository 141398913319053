<script>
export default {
  computed: {
    mainInfos () {
      return this.$store.state.mainInfos
    },
    teams () {
      return this.$store.state.teams
    },
    customContents () {
      return this.$store.state.customContents
    },
    nbTeams () {
      return this.$store.state.teams.length
    },
    nbCustomContent () {
      return this.$store.state.customContents.length
    },
    isToday () {
      return this.mainInfos.dateStatus === 'today'
    },
    isPast () {
      return this.mainInfos.dateStatus === 'past'
    },
    isFuture () {
      return this.mainInfos.dateStatus === 'future'
    },
    nbRequiredInvites () {
      let res = 0
      for (const team of this.teams) {
        for (const player of team.players) {
          if (!player.inviteSentAt) {
            res += 1
          }
        }
      }
      return res
    }
  }
}
</script>
