<template>
  <div>
    L'adresse est invalide.
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
