<template>
  <div>
    <div class="notification is-default">
      Retrouvez ici les selfies pris par les participants qui l'ont souhaité.
    </div>

    <div v-if="data.length === 0">
      <div class="notification is-link">
        Aucune photo n'a été effectuée pour le moment
      </div>

      <button @click="refresh" class="button is-link">
        Rafraichir
      </button>
    </div>
    <div v-if="data.length">

      <div
        v-for="teamPhotos in data"
        :key="teamPhotos.teamName"
      >
        <div class="team-name">
          {{ teamPhotos.teamName }}
        </div>
        <div class="team-photos">
          <img
            v-for="url in teamPhotos.pictureUrls"
            :key="url"
            :src="url"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin'
import axiosInstance from '@/api'

export default {
  name: 'EventPhotos',
  mixins: [BaseMixin],
  data () {
    return {
      data: []
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    async refresh () {
      const response = await axiosInstance.get(this.$store.state.gatheringUid + '/photos')
      this.data = response.data
    }
  }
}
</script>

<style lang="sass" scoped>
  .team-name
    text-align: left
    font-size: 18px
    font-weight: bold
    margin: 50px 0px 20px 0px
  .team-photos
    text-align: left
    img
      display: inline-block
      width: 400px
      padding: 10px

  button
    display: block
    margin: auto
    margin-top: 50px
</style>
