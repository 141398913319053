<template>
  <div>
    <article class="panel is-link" v-if="teams">
      <p class="panel-heading">
        <span v-if="!isPast">
          {{ nbRequiredInvites }} invitation<span v-if="nbRequiredInvites > 1">s</span> à effectuer
        </span>
        <span v-if="isPast">
          Historique des invitations
        </span>
      </p>
      <a class="panel-block is-active"
        v-for="entry in entries"
        :key="entry.key"
        :class="{'is-team': entry.kind === 'team'}"
      >
        <span v-if="entry.kind === 'team'" class="team">
          <i class="fa-solid fa-people-group"></i> {{ entry.team.name }}
        </span>
        <span v-else class="player">
          <span v-if="isPlayerInProgress(entry.player)">
            <i class="fa-solid fa-spinner fa-pulse"></i>
          </span>
          <span v-else>
            <span v-if="entry.player.inviteSentAt" class="mail-status">
              <i class="fa-solid fa-envelope-circle-check"></i>
              <span class="invite-datetime">{{ formatInviteDatetime(entry.player) }}</span>
            </span>
            <span v-else class="mail-status">
              <i class="fa-solid fa-circle-exclamation"></i>
            </span>
          </span>
          <span class="player-details">
            {{ entry.player.name }} - {{ entry.player.email }}
          </span>
          <button
            @click="sendInvite(entry.player.email)"
            v-if="!entry.player.inviteSentAt && !inProgress && !isPast"
            class="button is-small">
            <span class="icon is-small">
              <i class="fa-solid fa-paper-plane"></i>
            </span>
            <span>Envoyer</span>
          </button>
        </span>
      </a>
    </article>

    <button class="button is-link" v-if="nbRequiredInvites && !inProgress && !isPast" @click="sendAllInvites()">
      <span class="icon">
        <i class="fa-solid fa-paper-plane"></i>
      </span>
      <span v-if="nbRequiredInvites === 1">
        Envoyer l'invitation manquante
      </span>
      <span v-else>
        Envoyer les {{ nbRequiredInvites }} invitations manquantes
      </span>
    </button>

    <div class="notification is-link" v-if="allInvitesSent && !inProgress">
      Toutes les invitations ont été envoyées aux joueurs.
    </div>

    <button class="button is-warning" v-if="allInvitesSent && !inProgress && !isPast" @click="resendAllInvites()">
      <span class="icon">
        <i class="fa-solid fa-paper-plane"></i>
      </span>
      <span>
        Réenvoyer toutes les invitations
      </span>
    </button>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin'

export default {
  name: 'PlayerInvites',
  mixins: [BaseMixin],
  data () {
    return {
      inProgress: false,
      inProgressType: {}
    }
  },
  computed: {
    allInvitesSent () {
      let nbPlayers = 0
      for (const team of this.teams) {
        for (const player of team.players) {
          nbPlayers += 1
          if (!player.inviteSentAt) {
            return false
          }
        }
      }
      return nbPlayers !== 0
    },
    entries () {
      const res = []
      for (const team of this.teams) {
        res.push({
          kind: 'team',
          team
        })
        for (const player of team.players) {
          res.push({
            kind: 'player',
            player
          })
        }
      }
      return res
    }
  },
  methods: {
    isPlayerInProgress (player) {
      if (!this.inProgress) {
        return false
      }
      if (this.inProgressType.type === 'all') {
        return true
      }
      if (player.inviteSentAt) {
        return false
      }
      if (this.inProgressType.type === 'missing') {
        return true
      }
      if (this.inProgressType.type === 'specific') {
        return player.email === this.inProgressType.email
      }
      return false
    },
    formatInviteDatetime (player) {
      const inviteDate = new Date(player.inviteSentAt)
      return inviteDate.toLocaleDateString('fr-FR') + ' ' + inviteDate.toLocaleTimeString('fr-FR')
    },
    async sendInvite (email) {
      this.inProgress = true
      this.inProgressType = {
        type: 'specific',
        email
      }
      await this.$store.dispatch('sendInvite', email)
      this.inProgress = false
    },
    async sendAllInvites () {
      this.inProgress = true
      this.inProgressType = {
        type: 'missing'
      }
      await this.$store.dispatch('sendAllInvites', {})
      this.inProgress = false
    },
    async resendAllInvites () {
      this.inProgress = true
      this.inProgressType = {
        type: 'all'
      }
      await this.$store.dispatch('sendAllInvites', { force: true })
      this.inProgress = false
    }
  }
}
</script>

<style lang="sass" scoped>
  a.panel-block
    cursor: inherit !important
  .is-team
    background-color: #CCC
  .player
    margin-left: 5px
    .mail-status
      .svg-inline--fa
        margin-left: 20px
    .fa-envelope-circle-check
      color: green
    .fa-circle-exclamation
      color: orange
    .player-details
      margin-left: 10px
    button
      margin-left: 30px
  .invite-datetime
    font-size: 12px
    padding: 0px 5px
</style>
