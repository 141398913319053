<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile is-parent" v-if="isFuture">
        <article class="tile is-child notification is-info">
          <p class="title">
            <span v-if="nbTeams == 0">
              Renseigner les équipes
            </span>
            <span v-else>
              {{ nbTeams }} équipe<span v-if="nbTeams > 1">s</span>
            </span>
          </p>
          <p class="text">
            Renseignez les équipes qui participeront au parcours le jour J :<br/>
            <ul>
              <li>Un nom par équipe</li>
              <li>Les adresses email et noms des membres de chaque équipe</li>
            </ul>
          </p>
          <button @click="moveToTeams()" class="button is-light">
            Ajouter des équipes
          </button>
        </article>
      </div>
      <div class="tile is-parent" v-if="!isFuture">
        <article class="tile is-child notification is-info">
          <p class="title">
            Voir les photos
          </p>
          <p class="text">
            Retrouvez les photos prises par les participants
          </p>
          <button @click="moveToPhotos()" class="button is-light">
            Voir les photos
          </button>
        </article>
      </div>
      <div class="tile is-parent" v-if="isFuture">
        <article class="tile is-child notification is-success">
          <p class="title">
            <span v-if="nbCustomContent == 0">
              Ajouter une introduction au parcours
            </span>
            <span v-if="nbCustomContent > 0">
              {{ nbCustomContent }} contenu<span v-if="nbCustomContent > 1">s</span> personnalisé<span v-if="nbCustomContent > 1">s</span>
            </span>
          </p>
          <p class="text">
            Petit texte d'encouragement, images ou vidéo amusantes, diffusez un message aux joueurs avant le début de l'aventure.<br/><br/>
          </p>
          <button @click="moveToCustomizations()" class="button is-light">
            Ajouter du contenu
          </button>
        </article>
      </div>
      <div class="tile is-parent" v-if="!isFuture">
        <article class="tile is-child notification is-success">
          <p class="title">
            Voir les résultats
          </p>
          <p class="text">
            Visualisez les temps passés par chaque équipe
          </p>
          <button @click="moveToRankings()" class="button is-light">
            Voir le classement
          </button>
        </article>
      </div>
    </div>
    <div class="tile is-ancestor" v-if="!isPast">
      <div class="tile is-parent">
        <article class="tile is-child notification is-warning">
          <p class="title">Inviter les parcipants</p>
          <p class="text">
            Une fois le parcours finalisé, envoyez les invitations par email.<br/>
            Chaque participant recevra alors un lien lui permettant de lancer le parcours
            au sein de l'équipe qui lui a été attribuée.<br/><br/>

            Vous pouvez également, par sécurité, imprimer une feuille avec les QR codes des différentes équipes. Si un participant n'a
            pas reçu son invitation / l'a perdu, il pourra simplement scanner le papier le jour J.
          </p>
          <div class="buttons">
            <button @click="moveToInvites()" class="button is-light">
              Envoyer les invitations
            </button>
            <button @click="moveToQrCodes()" class="button is-light">
              Imprimer les QR Codes
            </button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin'

export default {
  name: 'AppHome',
  mixins: [BaseMixin],
  methods: {
    moveToCustomizations () {
      this.$router.push({ name: 'Customize' })
    },
    moveToInvites () {
      this.$router.push({ name: 'Invites' })
    },
    moveToTeams () {
      this.$router.push({ name: 'Teams' })
    },
    moveToPhotos () {
      this.$router.push({ name: 'Photos' })
    },
    moveToRankings () {
      this.$router.push({ name: 'Rankings' })
    },
    moveToQrCodes () {
      this.$router.push({ name: 'QrCodes' })
    }
  }
}
</script>

<style lang="sass" scoped>
  .tile
    p.text
      padding: 10px
      text-align: left
      ul
        li
          list-style-type: disc
          margin-left: 40px
    button
      margin-top: 20px

  .buttons
    justify-content: center

</style>
