import { createRouter, createWebHistory } from 'vue-router'
import Invites from '@/views/Invites.vue'
import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'
import Teams from '@/views/Teams.vue'
import Customize from '@/views/Customize.vue'
import Photos from '@/views/Photos.vue'
import QrCodes from '@/views/QrCodes.vue'
import Rankings from '@/views/Rankings.vue'

const routes = [
  {
    path: '/',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/:gatheringUid/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:gatheringUid/teams',
    name: 'Teams',
    component: Teams
  },
  {
    path: '/:gatheringUid/customize',
    name: 'Customize',
    component: Customize
  },
  {
    path: '/:gatheringUid/invites',
    name: 'Invites',
    component: Invites
  },
  {
    path: '/:gatheringUid/photos',
    name: 'Photos',
    component: Photos
  },
  {
    path: '/:gatheringUid/rankings',
    name: 'Rankings',
    component: Rankings
  },
  {
    path: '/:gatheringUid/qrcodes',
    name: 'QrCodes',
    component: QrCodes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
