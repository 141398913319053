<template>
  <div>
    <div class="notification is-default">
      Le contenu ci-dessous sera affiché aux joueurs en début de parcours.
    </div>

    <article class="panel is-link">
      <p class="panel-heading">
        {{ customContents.length }} contenu<span v-if="customContents.length > 1">s</span> personnalisé<span v-if="customContents.length > 1">s</span>
      </p>
      <a class="panel-block is-active"
        v-for="customContent in customContents"
        :key="customContent.uid"
        @click="showEditCustomContent(customContent.uid)"
      >
        <span class="panel-icon" @click.stop="moveUp(customContent.uid)" v-if="isFuture">
          <i class="fa-solid fa-arrow-up"></i>
        </span>
        <span class="panel-icon" @click.stop="moveDown(customContent.uid)" v-if="isFuture">
          <i class="fa-solid fa-arrow-down"></i>
        </span>
        <span class="panel-icon">
          <i class="fa-solid fa-font" v-if="customContent.contentType === 'TEXT'" ></i>
          <i class="fa-solid fa-image" v-if="customContent.contentType === 'IMAGE'" ></i>
          <i class="fa-solid fa-video" v-if="customContent.contentType === 'VIDEO'" ></i>
        </span>
        <span v-if="customContent.contentType === 'TEXT'">
          {{ customContent.text }}
        </span>
        <span v-if="customContent.contentType === 'IMAGE'">
          <img :src="customContent.imageUrl"/>
        </span>
        <span v-if="customContent.contentType === 'VIDEO'">
          <a :href="customContent.youtubeVideo" target="_blank">
            {{ customContent.youtubeVideo }}
          </a>
        </span>
      </a>
      <div class="panel-block" v-if="isFuture">
        <button class="button is-link is-outlined is-fullwidth" @click="showAddCustomContentModal()">
        Ajouter
        </button>
      </div>
    </article>

    <CustomContentModal v-model:displayed="modalDisplayed" :customContentUid="editedContentUid"/>

  </div>
</template>

<script>
import CustomContentModal from '@/components/CustomContentModal'
import BaseMixin from '@/mixins/BaseMixin'

export default {
  name: 'CustomContentList',
  mixins: [BaseMixin],
  components: { CustomContentModal },
  data () {
    return {
      modalDisplayed: false,
      editedContentUid: null
    }
  },
  methods: {
    showAddCustomContentModal () {
      this.editedContentUid = null
      this.modalDisplayed = true
    },
    showEditCustomContent (contentUid) {
      this.editedContentUid = contentUid
      this.modalDisplayed = true
    },
    moveUp (contentUid) {
      this.$store.dispatch('moveUpCustomContent', contentUid)
    },
    moveDown (contentUid) {
      this.$store.dispatch('moveDownCustomContent', contentUid)
    }
  }
}
</script>

<style lang="sass" scoped>
  img
    max-height: 150px

  .fa-arrow-up, .fa-arrow-down
    color: #AAA
</style>
