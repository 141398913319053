<template>
  <div>
    <div class="notification is-default">
      Retrouvez ici le classement des participants
    </div>

    <div v-if="data.length === 0">
      <div class="notification is-warning">
        Aucune information disponible
      </div>

      <button @click="refresh" class="button is-warning">
        Rafraichir
      </button>
    </div>
    <div v-if="data.length">
      <table class="table" v-if="data.length > 0">
        <tr>
          <th></th>
          <th>Equipe</th>
          <th><abbr title="Progression">%</abbr></th>
          <th><abbr title="Total temps passé">Temps total</abbr></th>
          <th>Dont bonus</th>
          <th>Dont malus</th>
        </tr>
        <tr
            v-for="(ranking,index) in data"
            :key="ranking.teamName"
        >
          <th><span v-if="ranking.progress === 100">{{ index + 1 }}</span></th>
          <th>{{ ranking.teamName }}</th>
          <td>{{ ranking.progress }}%</td>
          <td>{{ formatTime(ranking.totalTimeElapsed) }}</td>
          <td>{{ formatTime(ranking.bonuses) }}</td>
          <td>{{ formatTime(ranking.penalties) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin'
import axiosInstance from '@/api'
import { formatElapsedTime } from '@/functions/time'

export default {
  name: 'EventRankings',
  mixins: [BaseMixin],
  data () {
    return {
      data: []
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    async refresh () {
      const response = await axiosInstance.get(this.$store.state.gatheringUid + '/rankings')
      this.data = response.data
    },
    formatTime (value) {
      if (value === 0) {
        return '-'
      }
      return formatElapsedTime(value, true)
    }
  }
}
</script>

<style lang="sass" scoped>
  button
    display: block
    margin: auto
    margin-top: 50px

  table
    width: 100%
    background-color: whitesmoke
    th:not(:nth-child(2))
      text-align: center
    th, td
      border: solid 1px #CCC

</style>
